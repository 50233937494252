
export default {
  data: () => ({
    links: [
      { text: "About", route: "/about/" },
      { text: "FAQs", route: "/info/faq/" },
      { text: "Schools", route: "/schools/" },
      { text: "Contact", route: "/info/contact/" },
      { text: "Terms", route: "/terms/" },
      { text: "Donate", route: "/donate/" }
    ],
    icons: [
      {
        icon: "mdi-facebook",
        route: "https://www.facebook.com/showcasebase"
      },
      { icon: "mdi-twitter", route: "https://twitter.com/showcasebase" },
      {
        icon: "mdi-linkedin",
        route: "https://www.linkedin.com/company/showcasebase"
      },
      {
        icon: "mdi-instagram",
        route: "https://www.instagram.com/showcasebasegram/"
      }
    ]
  })
};
