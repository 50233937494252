import initialState from "./state";

export default {
  RESET_STORE: state => {
    Object.assign(state, initialState());
  },

  SET_AUTH_USER: (state, { authUser, claims }) => {
    state.authUser = {
      uid: authUser.uid,
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      photoURL: authUser.photoURL || null,
      displayName: authUser.displayName,
      allClaims: claims,
      providerId: authUser.providerData[0].providerId
    };
  },
  setUser: (state, user) => {
    state.user = user
  },
  setUserMenuItems: (state, action) => {
    if (action === "createGraduate") {
      state.userMenuItems.unshift({
        text: "Create Profile",
        icon: "mdi-account-plus",
        route: "/account/create/"
      });
    }
    if (action === "displayGraduate") {
      state.userMenuItems.splice(1, 0, {
        text: "My Graduate Profile",
        icon: "mdi-account-tie",
        route: "/account/graduate/"
      });
    }
  },
  deleteCreateProfileItem: (state) => {
      state.userMenuItems.shift();
      state.userMenuItems.splice(1, 0, {
        text: "My Graduate Profile",
        icon: "mdi-account-tie",
        route: "/account/graduate/"
      });
  },
  deleteGraduateProfileItem: (state) => {
    state.userMenuItems = state.userMenuItems.filter(item => item.text !== "My Graduate Profile" )
    state.userMenuItems.unshift({
      text: "Create Profile",
      icon: "mdi-account-plus",
      route: "/account/create/"
    });
  },

  SET_SCHOOLS: (state, schools) => {
    state.schools = schools
  },
  SHOW_MESSAGE: (state, payload) => {
    console.log("show message", payload.content, payload.color)
    state.snackbar.content = payload.content
    state.snackbar.color = payload.color
  }
};
