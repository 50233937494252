
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    user: {
      type: Object,
    },
  },
  emits: ['log-out'],
  data() {
    return {
      userMenu: false,
      graduate: [],
      noGraduate: false,
      // userMenuItems: [
      //   {
      //     text: "Account Information",
      //     icon: "mdi-card-account-details",
      //     route: "/account/user/"
      //   },
      //   {
      //     text: "Billing History",
      //     icon: "mdi-account-cash",
      //     route: "/account/billing/"
      //   }
      //   // { text: "Custom Claims", icon: "mdi-flag", route: "/account/claims/" }
      //   // { text: "Logout", icon: "mdi-logout-variant" }
      // ]
    }
  },
  computed: {
    ...mapState({
      authUser: (state) => state.authUser,
    }),
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      userMenuItems: 'userMenuItems',
      emailVerified: 'emailVerified',
      isAdmin: 'isAdmin',
      isGraduate: 'isGraduate',
      isIndustry: 'isIndustry',
    }),
    // isAdmin() {
    //   return this.authUser.allClaims.admin ? true : false;
    // },
    // isGraduate() {
    //   return this.authUser.allClaims.stripeRole === "graduate" ? true : false;
    // },
    // isIndustry() {
    //   if (this.authUser) {
    //     return this.authUser.allClaims.stripeRole === "industry" ? true : false;
    //   } else return;
    // },
    // isFree() {
    //   if (this.authUser) {
    //     if (this.authUser.allClaims.stripeRole !== "graduate" && "industry")
    //       return true;
    //   } else return;
    // },
    role() {
      if (this.authUser?.allClaims?.stripeRole) {
        if (this.authUser.allClaims.stripeRole == null) {
          return 'free'
        } else {
          return this.authUser.allClaims.stripeRole
        }
      } else if (this.authUser?.allClaims.admin) {
        return 'admin'
      } else if (this.authUser) {
        return 'free'
      } else {
        return
      }
    },
  },
  // methods: {
  //   async getGraduatebyId() {
  //     // console.log("Value of isLoggedIn");
  //     // console.log(this.isLoggedIn);
  //     if (this.isLoggedIn && this.authUser.allClaims.stripeRole) {
  //       try {
  //         const doc = await this.$fire.firestore
  //           .collection("graduates")
  //           .where("uid", "==", this.authUser.uid)
  //           .get();
  //         // console.log(doc);

  //         if (doc.empty) {
  //           console.log("no graduate found");
  //           if (
  //             this.authUser.allClaims.admin ||
  //             this.authUser.allClaims.stripeRole === "graduate"
  //           ) {
  //             this.$store.dispatch('setUserMenuItems', 'createGraduate');
  //             // this.userMenuItems.unshift({
  //             //   text: "Create Profile",
  //             //   icon: "mdi-account-plus",
  //             //   route: "/account/create/"
  //             // });
  //           }
  //         } else {
  //           doc.forEach(doc => {
  //             this.graduate.push(doc.data());
  //           });
  //           // console.log(this.graduate[0]);
  //           if (
  //             this.authUser.allClaims.admin ||
  //             this.authUser.allClaims.stripeRole === "graduate"
  //           ) {

  //             this.$store.dispatch('setUserMenuItems', 'displayGraduate');
  //             // this.userMenuItems.splice(1, 0, {
  //             //   text: "My Graduate Profile",
  //             //   icon: "mdi-account-tie",
  //             //   route: "/account/graduate/"
  //             // });
  //           }
  //         }
  //       } catch (error) {
  //         console.log(error);
  //         this.notification = error.message;
  //         this.snackbar = true;
  //         this.loading = false;
  //       }
  //     }
  //   }
  // },
  // mounted() {
  //   if (this.authUser) {
  //     this.getGraduatebyId();
  //   }
  // }
}
