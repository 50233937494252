export default function({ store, error, redirect }) {
  // Only allow industry users and admins past here
  if (!store.getters.isIndustry && !store.getters.isAdmin) {
    error({
      message: "You are not an industry user",
      statusCode: 403
    });
    return redirect("/login/");
  }
}
