export default {
  isLoggedIn: state => {
    try {
      return state.authUser.uid !== null;
    } catch {
      return false;
    }
  },
  emailVerified: state => {
    try {
      return state.authUser.emailVerified === true;
    } catch {
      return false;
    }
  },
  isAdmin: state => {
    try {
      return state.authUser.allClaims.admin === true;
    } catch {
      return false;
    }
  },
  isGraduate: state => {
    try {
      return state.authUser.allClaims.stripeRole === 'graduate';
    } catch {
      return false;
    }
  },
  isIndustry: state => {
    try {
      return state.authUser.allClaims.stripeRole === 'industry';
    } catch {
      return false;
    }
  },
  isPasswordAuth: state => {
    try {
      return state.authUser.providerId === 'password';
    } catch {
      return false;
    }
  },
  regions: state => state.regions,
  courseOptions: state => state.courseOptions,
  userMenuItems: state => state.userMenuItems,
  user: state => state.user,
  schoolById: state => (id) => {
    return state.schools.find(school => school.id === id)
  },
  allSchools: state => {
    try {
      return state.schools;
    } catch {
      console.log('catch allSchools')
      return false;
    }
  },
  graduateSchools: state => {
    try {
      return state.schools.filter(school => school.graduates === true)
    } catch (error) {
      return false
    }
  }
};
