import { render, staticRenderFns } from "./TheNavbar.vue?vue&type=template&id=96fc4f36&scoped=true&"
import script from "./TheNavbar.vue?vue&type=script&lang=js&"
export * from "./TheNavbar.vue?vue&type=script&lang=js&"
import style0 from "./TheNavbar.vue?vue&type=style&index=0&id=96fc4f36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96fc4f36",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdminMenu: require('/home/runner/work/showcase/showcase/components/Navigation/AdminMenu.vue').default,UserMenu: require('/home/runner/work/showcase/showcase/components/Navigation/UserMenu.vue').default})
