import { render, staticRenderFns } from "./error.vue?vue&type=template&id=e8cf0c90&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=e8cf0c90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8cf0c90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheNavbar: require('/home/runner/work/showcase/showcase/components/Navigation/TheNavbar.vue').default,TheFooter: require('/home/runner/work/showcase/showcase/components/Navigation/TheFooter.vue').default})
