export default {
  async nuxtServerInit({ dispatch }, ctx) {
    // Get all schools
    await dispatch("getSchools");
    if (this.$fire.auth === null) {
      throw "nuxtServerInit Example not working - this.$fire.auth cannot be accessed.";
    }

    if (ctx.$fire.auth === null) {
      throw "nuxtServerInit Example not working - ctx.$fire.auth cannot be accessed.";
    }

    if (ctx.app.$fire.auth === null) {
      throw "nuxtServerInit Example not working - ctx.$fire.auth cannot be accessed.";
    }

    // INFO -> Nuxt-fire Objects can be accessed in nuxtServerInit action via this.$fire___, ctx.$fire___ and ctx.app.$fire___'

    /** Get the VERIFIED authUser from the server */
    if (ctx.res && ctx.res.locals && ctx.res.locals.user) {
      const { allClaims: claims, ...authUser } = ctx.res.locals.user;

      console.info(
        "Auth User verified on server-side. User: ",
        authUser,
        "Claims:",
        claims
      );

      await dispatch("onAuthStateChanged", {
        authUser,
        claims
      });
    }
  },

 async onAuthStateChanged(context, { authUser, claims }) {
    if (!authUser) {
      context.commit("RESET_STORE");
      return;
    }
    context.commit("SET_AUTH_USER", { authUser, claims });
    await context.dispatch("setUser", authUser.uid);
  },

  checkVuexStore(ctx) {
    if (this.$fire.auth === null) {
      throw "Vuex Store example not working - this.$fire.auth cannot be accessed.";
    }

    alert(
      "Success. Nuxt-fire Objects can be accessed in store actions via this.$fire___"
    );
    return;
  },
  setUserMenuItems: (context, action) => {
    context.commit('setUserMenuItems', action);
  },
  deleteCreateProfileItem: (context) => {
    context.commit('deleteCreateProfileItem');
  },
  deleteGraduateProfileItem: (context) => {
    context.commit('deleteGraduateProfileItem');
  },
  async getSchools({ commit }) {
    const doc = await this.$fire.firestore.collection("schools").doc("all-schools").get()
    const { schools } = doc.data()
    // Sort schools by name
    schools.sort((a,b) => a.name < b.name ? -1 : 1)
    commit('SET_SCHOOLS', schools)
  },
  async setUser({commit}, uid) {
    try {
      const doc = await this.$fire.firestore
        .collection("users")
        .doc(uid)
        .get();
      const data = doc.data();
      if (!data.address) {
        data.address = {};
        data.address.name = "";
      }
      const user  = data
      commit('setUser', user);
    } catch (error) {
      console.log(error);
    }
  }
};
