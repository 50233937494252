
import { mapState, mapGetters } from "vuex";
export default {
  data: () => ({
    fab: false,
    loggedIn: false,
    user: {},
    graduate: [],
    drawer: false,
    items: [
      { title: "Home", icon: "mdi-home", route: "/" },
      {
        title: "Graduates",
        icon: "mdi-account-multiple",
        route: "/graduates/"
      },
      { title: "List of Schools", icon: "mdi-school", route: "/schools/" }
    ],
    userDrawer: false,
    selectedItem: 0,
    submenuInfo: [
      { title: "Why Online Showcasing?", icon: "mdi-format-list-bulleted", route: "/info/why-showcasing/" },
      { title: "FAQs", icon: "mdi-format-list-bulleted", route: "/info/faq/" },
      { title: "Pricing", icon: "mdi-currency-usd", route: "/info/pricing/" },
      { title: "Help Hub", icon: "mdi-help", route: "/info/helphub/" },
      {
        title: "Contact",
        icon: "mdi-message-text-outline",
        route: "/info/contact/"
      }
    ],
    submenuAbout: [
      { title: "Our Story", route: "/about/story/" },
      { title: "Meet The Team", route: "/about/team/" },
      { title: "Testimonials", route: "/about/testimonials/" },
      {
        title: "Press",
        route: "/about/press/"
      }
    ]
  }),
  mounted() {
    this.user = this.userData;
    if (this.authUser) {
      this.getGraduatebyId();
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.authUser,
      userData: state => state.user
    }),
    ...mapGetters([
      "isLoggedIn",
      "userMenuItems",
      "isAdmin",
      "isGraduate",
      "isIndustry",
    ]),
    role() {
      if (this.authUser?.allClaims?.stripeRole) {
        if (this.authUser.allClaims.stripeRole == null) {
          return "free";
        } else {
          return this.authUser.allClaims.stripeRole;
        }
      } else if (this.authUser?.allClaims.admin) {
        return "admin";
      } else if (this.authUser) {
        return "free";
      } else {
        return;
      }
    }
  },
  methods: {
    // checkAuth() {
    //   this.$fire.auth.onAuthStateChanged(user => {
    //     if (user) {
    //       this.loggedIn = true;
    //       this.user = user;
    //     } else {
    //       this.loggedIn = false;
    //     }
    //   });
    // },
    logout() {
      try {
        this.$fire.auth.signOut();
        this.user = {}
        this.$fire.analytics.logEvent('logout');
        this.$router.push("/");
      } catch (e) {
        alert(e);
      }
    },
    // async getUser() {
    //   console.log("Value of isLoggedIn");
    //   console.log(this.isLoggedIn);
    //   if (this.isLoggedIn) {
    //     try {
    //       const doc = await this.$fire.firestore
    //         .collection("users")
    //         .doc(this.authUser.uid)
    //         .get();
    //       this.user = doc.data();
    //       const data = doc.data();
    //       if (!data.address) {
    //         data.address = {};
    //         data.address.name = "";
    //       }
    //       this.user = data;
    //     } catch (error) {
    //       this.notification = error.message;
    //       this.snackbar = true;
    //       console.log(error);
    //     }
    //   }
    // },
    async getGraduatebyId() {
      // console.log("Value of isLoggedIn");
      // console.log(this.isLoggedIn);
      if (this.isLoggedIn && this.authUser.allClaims.stripeRole === 'graduate') {
        try {
          const doc = await this.$fire.firestore
            .collection("graduates")
            .where("uid", "==", this.authUser.uid)
            .get();
          // console.log(doc);

          if (doc.empty) {
            console.log("no graduate found");
            this.$store.dispatch('setUserMenuItems', 'createGraduate');
          } else {
            doc.forEach(doc => {
              this.graduate.push(doc.data());
            });
            // console.log(this.graduate[0]);
             this.$store.dispatch('setUserMenuItems', 'displayGraduate');
          }
        } catch (error) {
          console.log(error);
          this.notification = error.message;
          this.snackbar = true;
          this.loading = false;
        }
      }
    }
  }
};
