export default function({ store, error, redirect }) {
  // Only allow admins past here
  if (!store.getters.isAdmin) {
    error({
      message: "You are not an administrator",
      statusCode: 403
    });
    return redirect("/login/");
  }
}
